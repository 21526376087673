<template>
  <div class="PharmacyPage">
    <div class="notop"></div>
    <div class="searchBox">
      <div class="searchBox_innerbox">
        <p class="titleB">附近药房</p>
      </div>
      <div class="search">
        <div class="selectStyle2">
          <el-select popper-class="popper-scrollbar-show" v-model="provinceName"  @change="citysName=''" placeholder="选择省份"  class="selectinners" size="medium">
                <el-option
                  v-for="item in provinces"
                  :key="item.provinceName"
                  :label="item.provinceName"
                  @click.native="setProvinces(item)"
                  :value="item.provinceName">
                </el-option>
              </el-select>
          </div>

          <div class="selectStyle2">
            <el-select popper-class="popper-scrollbar-show" :disabled="!provinceName" v-model="citysName" placeholder="选择城市" class="selectinners" size="medium">
                <el-option
                  v-for="item in citys"
                  :key="item.cityName"
                  :label="item.cityName"
                  :value="item.cityName">
                </el-option>
              </el-select>
          </div>

          <div class="selectStyle_button" @click="serchs">查询</div>
      </div>

      <div class="tableBox">
        <table>
        <tr class="title"><td>药房名称</td><td>地址</td></tr>
        <tr class="conner" v-for="(item,index) in pharmacyDemos" :key="index"><td><p>{{ item.name }}</p></td><td><p>{{ item.address }}</p></td></tr>
        
      </table>
      </div>

      <div class="appTableBox">
        <p class="title">药房名称</p>
        <div class="tableList" v-for="(item,index) in pharmacyDemos" :key="index">
          <p class="p1">{{ item.name }}</p>
          <p class="p2">{{ item.address }}</p>
        </div>
      </div>

      <div class="demonstration">
        <el-pagination
          :hide-on-single-page="true"
          layout="prev, pager, next"
          :current-page.sync="pages"
          :pager-count="5"
          @current-change="currentChange"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import '../style/pharmacy/PCstyle.scss'
import '../style/pharmacy/APPstyle.scss'

export default {
  name: 'PharmacyPage',
  props: {
  },
  data () {
    return {
      provinces:[],
      citys:{},
      provinceName:'',
      citysName:'',
      pharmacyAddress:[],
      pharmacyAddressTWO:[],
      pharmacyDemos:[],
      total:0,
      pages:1
    }
  },
  created () {

    axios.request({
       url: '/JSON/provinces.json',//直接填写json文件在public下的路径即可
       method: 'get',
      }).then(res => {
        this.provinces = res.data.provinces

        if(sessionStorage.getItem('provinceName')){
            this.provinceName=sessionStorage.getItem('provinceName')
            this.provinces.forEach(item=>{
              if(item.provinceName===this.provinceName){
                this.citys=item.citys
                  if(sessionStorage.getItem('citysName')){
                      this.citysName=sessionStorage.getItem('citysName')
                    }
                    }
            })
          }
      })

    axios.request({
       url: '/JSON/pharmacy.json',//直接填写json文件在public下的路径即可
       method: 'get',
      }).then(res => {
        this.pharmacyAddress = res.data.pharmacyAddress
        this.pharmacyAddressTWO = JSON.parse(JSON.stringify(this.pharmacyAddress))
        this.pharmacyDemos = this.pharmacyAddressTWO.slice(0, 10)
        this.total = this.pharmacyAddressTWO.length
      })

      
      setTimeout(()=>{
        this.serchs()
      },400)
    
  },
  methods: {
    setProvinces(item){
      this.citys = item.citys
      console.log(item)
    },
    currentChange(page){
      const pageStar = (page-1)*10
      const pageEnd = page*10-1
      this.pharmacyDemos = this.pharmacyAddressTWO.slice(pageStar, pageEnd)
    },
    serchs(){
      
      let newarr = []
      if(this.provinceName&&!this.citysName){
        this.pharmacyAddress.forEach(res=>{
          if(res.province===this.provinceName){
            newarr.push(res)
          }
        })
        this.pharmacyAddressTWO = newarr
        this.total = this.pharmacyAddressTWO.length
        this.pharmacyDemos = this.pharmacyAddressTWO.slice(0, 10)
      }else if (this.provinceName&&this.citysName){
        this.pharmacyAddress.forEach(res=>{
          if(res.province===this.provinceName&&res.city===this.citysName){
            newarr.push(res)
          }
        })
        this.pharmacyAddressTWO = newarr
        this.total = this.pharmacyAddressTWO.length
        this.pharmacyDemos = this.pharmacyAddressTWO.slice(0, 10)
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>