<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'App',
    data() {
      return {

      }
    },
    created () {
      let userAgent = navigator.userAgent.toLowerCase();
      // 控制显示比例
      window.onresize = ()=>{
          // 用 test 匹配浏览器信息
          if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(userAgent)) {
            console.log('移动端')
          } else {
          //   // 控制显示比例
            this.handleScreen();
          }
      }
    },
    mounted() {
      let userAgent = navigator.userAgent.toLowerCase();
      // 用 test 匹配浏览器信息
      if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(userAgent)) {
        console.log('移动端')
      } else {
      //   // 控制显示比例
        window.onload = this.handleScreen();
      }
    },
    methods: {
      // 控制显示比例
      handleScreen() {
        const m = this.detectZoom();
        console.log(m)
        document.body.style.zoom = 100 / Number(m);

      },
      detectZoom() {
        let ratio = 0,
          screen = window.screen,
          ua = navigator.userAgent.toLowerCase();
        if (window.devicePixelRatio !== undefined) {
          ratio = window.devicePixelRatio;
        } else if (~ua.indexOf('msie')) {
          if (screen.deviceXDPI && screen.logicalXDPI) {
            ratio = screen.deviceXDPI / screen.logicalXDPI;
          }
        } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
          ratio = window.outerWidth / window.innerWidth;
        }
        if (ratio) {
          ratio = Math.round(ratio * 100);
        }
        return ratio;
      },
    }
  }
</script>

<style lang="scss">
  #app {
    width: 100%;
    height: 100%;
    padding: 0;
  }
</style>