<template>
  <div class="video">
    <div class="notop"></div>
    <div class="videoBox">
      <p class="title">注射视频</p>
      <div class="video">
        <video src="../assets/video/video.mp4" controls class="videoStyle" ></video>
      </div>
    </div>
  </div>
</template>

<script>
import '../style/video/PCstyle.scss'
import '../style/video/APPstyle.scss'

export default {
  name: 'VideoPage',
  props: {
    msg: String
  },
  data () {
    return {
      
    }
  },
  created () {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
}
</script>

<style>

</style>