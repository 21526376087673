<template>
  <div class="homepage">
    <div class="nobox" ></div>
    <div class="homeimg">
      <!-- <div class="isshow">
        <p class="title">希敏佳</p>
      <p class="title2">(培塞利珠单抗注射液)</p>
      <p class="connerText">Cimzia（中文商品名：希敏佳®，通用名：certolizumab pegol，培塞利珠单抗注射液）； 希敏佳为优时比（UCB ）产品。在中国，Cimzia于2019年7月获得国家药品监督管理局（NMPA）批准， 用于中度至重度类风湿性关节炎（RA）患者的治疗。 Cimzia是中国获批的一款在说明书中注明如有临床需要孕期全程均可使用的治疗类风湿关节炎生物制剂。 2019年12月，Cimzia正式在中国上市。 希敏佳注射指导视频 希敏佳说明书 “佳有希望”项</p>

      <div class="homeimgButton">
        <div class="buttonA">查看详情</div>
        <div class="buttonB">
          <div class="buttonB_icon">
            <i class="el-icon-caret-right" style="font-size: .26rem;color: #58386B;"></i>
          </div>
          了解我们</div>
      </div>
      </div> -->
    </div>

    <!-- <div class="appTitle">
      <p class="title1">希敏佳(培塞利珠单抗注射液)</p>
      <p class="title2">在中国，Cimzia于2019年7月获得国家药品监督管理局（NMPA）批准， 用于中度至重度类风湿性关节炎（RA）患者的治疗。</p>
      <div class="buttom">
        <div class="buttomText">查看详情</div>
        <div class="icons"></div>
      </div>
    </div> -->

    <!-- <div class="welcome">
      <img src="../assets/img/home/Images.png" class="masterImg" alt="Images">
      <div class="ImagesConner">
        <p class="ImagesConner_title">欢迎来到<br/>希敏佳®官方网站</p>
        <div class="ImagesConner_text">
          <img class="ImagesConner_textA" src="../assets/img/home/read.png" alt="read">
          <div class="ImagesConner_textB">
            <p class="ImagesConner_textB_title">请输入产品批号：</p>
            <p class="ImagesConner_textB_conner">为了更好，更合规地提供相关知识和信息给到正在使用希敏佳产品的患者，我们需要您在浏览本网站相关专业内容前输入产品批号，感谢您的理解和支持。</p>
          </div>
        </div>

        <div class="ImagesConner_tags">
          <div><img src="../assets/img/home/check.png" alt="check"> 给您更精确的健康知识推送</div>
          <div class="tg2"><img src="../assets/img/home/check.png" alt="check" > 与更多使用希敏佳产品的患者交流经验</div>
        </div>
        <div class="ImagesConner_tags2">
          <div><img src="../assets/img/home/check.png" alt="check"> 实时了解最新健康资讯</div>
          <div class="tg3"><img src="../assets/img/home/check.png" alt="check" > 专业团队为能提供一对一健康方案</div>
        </div>

        <div class="ImagesConner_button">我确认我是正在使用希敏佳的患者</div>
      </div>
    </div> -->


    <!-- <div class="searchBox">
      <div class="searchBox_innerbox">
        <p class="titleA">可售药店</p>
        <p class="titleB">可售药店查询</p>
      </div>

      <div class="searchBox_innerbox2">
          <p>您可以选择您所在的省份和城市后，出现对应的药店信息。您可以在这些药品凭专科医生有效处方购买到希敏佳产品。</p>

          <div class="selectStyle">
            <el-select popper-class="popper-scrollbar-show" v-model="provinceName" @change="citysName=''" placeholder="选择省份" size="medium" style="width: 100%;">
                <el-option
                  v-for="item in provinces"
                  :key="item.provinceName"
                  :label="item.provinceName"
                  @click.native="setProvinces(item)"
                  :value="item.provinceName">
                </el-option>
              </el-select>
          </div>

          <div class="selectStyle">
            <el-select popper-class="popper-scrollbar-show" :disabled="!provinceName" v-model="citysName" placeholder="选择城市" size="medium" style="width: 100%;">
                <el-option
                  v-for="item in citys"
                  :key="item.cityName"
                  :label="item.cityName"
                  :value="item.cityName">
                </el-option>
              </el-select>
          </div>

          <div class="selectStyle_button" @click="toSerch">查询</div>
      </div>
    </div> -->
  </div>
</template>

<script>
// import axios from 'axios'
import '../style/home/PCstyle.scss'
import '../style/home/APPstyle.scss'

export default {
  name: 'HomePage',
  props: ['getHomeData'],
  data () {
    return {
      // provinces:{},
      // citys:{},
      // provinceName:'',
      // citysName:''
    }
  },
  created () {
    // axios.request({
    //    url: '/JSON/provinces.json',//直接填写json文件在public下的路径即可
    //    method: 'get',
    //   }).then(res => {
    //     this.provinces = res.data.provinces
    //   })
  },
  methods: {
    // setProvinces(item){
    //   this.citys = item.citys
    //   console.log(item)
    // },
    // toSerch(){
    //   sessionStorage.setItem('provinceName',this.provinceName)
    //   sessionStorage.setItem('citysName',this.citysName)
    //   this.getHomeData(3)
    // }
  }
}
</script>

<style scoped lang="scss">

</style>
