<template>
  <div class="instructions">
    <div class="notop"></div>
    <div class="instructionsBox">
      <p class="title">说明书</p>
        <div class="box"  @click="toInstructions">
          <img src="../assets/img/instructions/pdf.png" alt="pdf">
          <p>说明书<br/><br/>请注意：本产品为处方药品，治疗应在具有对本品适应症诊断和治疗经验的专科医师的指导监控下进行。请点击左侧图标查看说明书。</p>
        </div>

        <div class="appbox">
          <p class="title">请注意:本产品为处方药品，治疗应在具有对本品适应症诊断和治疗经验的专科医师的指导监控下进行。请点击左侧图标查看说明书。</p>
          <div class="pdfbox"  @click="toInstructions">
            <div class="pdfboxlogo">PDF</div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import '../style/instructions/PCstyle.scss'
import '../style/instructions/APPstyle.scss'

export default {
  name: 'InstructionsPage',
  props: {
    msg: String
  },
  data () {
    return {
      
    }
  },
  created () {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  methods: {
    toInstructions(){
      window.open("/sms.pdf")
      // window.open('http://ucbcares.com.cn/patients/inflammatory-arthritis/zh/Information/20230113-Cimzia%20CCDS5.0%20approval%20letter%20PI.pdf','_blank')
    }
  }
}
</script>

<style>

</style>